<template>
  <div class="backShadow">
    <div v-if="!sendingReset && !faultOnSendReset && !successOnSendReset" class="modal-container"> 
      <label class="textAverage">Redefinir Senha</label>
      <input type="email" placeholder="E-mail" class="textAverage" v-model="email"/>
      <div class="btn btnAverageSize btnTypeSave btnLogin" v-on:click="login" >Redefinir</div>
    </div> 
    <PopUp  msg="Enviando e-mail de redefinição de senha..." :show="sendingReset" type="Loader"/> 
    <PopUp  v-bind:msg="faultMsg" :show="faultOnSendReset" type="Fault" :onOk="popUpFaultButtonOk"/> 
    <PopUp  msg='E-mail enviado com sucesso' :show="successOnSendReset" type="Confirmation" :onOk="popUpSucessButtonOk"/>              
  </div>
</template>
<script>
  import UserControl from '@/sys/Control/User/UserControl'
  import PopUp from '@/views/PopUp/PopUp.vue'
  import router from '@/router/index'
  export default {
    components:{PopUp},
    props:{
      path:{
        default:null
      }
    },
    data() {
      return {
        sendingReset:false,
        faultOnSendReset:false,
        successOnSendReset:false,
        faultMsg:null,
        email:null,
        password:null
      }
    },
    methods:{
      login: function(){
        this.sendingReset=true;
        UserControl.sendResetPasswordEmail(this.email).then(result=>{
          console.log(result);
          this.successOnSendReset=true;
          this.sendingReset=false;
        }).catch(e=>{            
          this.faultMsg=e;           
          this.faultOnSendReset=true;
          this.sendingReset=false;
        });         
      },
      popUpFaultButtonOk: function(){
        this.faultOnSendReset=false;
      },
      popUpSucessButtonOk: function(){
        this.successOnSendReset=false;
        router.push({path:'/Login'});
      }
    }
  }  
</script>

<style scoped>
  label{
    width: 300px;
    text-align: center;
    margin:0px 0px 0px 0px ; 
  }
  input{
    height: 50px;
    width: 300px;
    background-color: #1e2227;
    border: none;
    outline: none;
    border-radius: 4px;
    text-align: center;
    margin:20px 0px 0px 0px ;
  }
  .btnLogin{
    width: 300px;
    margin:20px 0px 0px 0px ;
  }
</style>
